.card {
    width: 90%;
    left: 10px;
    margin: 0px 10px 10px 10px;
}

.loadingSpin {
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 30%;
    right: 30%;
  
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}